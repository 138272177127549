<template>
    <v-row class="pa-0 isos-container" :justify="alignment == null ? 'center isos-container' : alignment">
        <v-col :cols="mobile ? '3' : 'auto'">
            <v-img :width="mobile ? '100%' : 100" src="@/assets/isos/isae3000_t2.png" class="neonBoxRound"/>
        </v-col>
        <v-col :cols="mobile ? '3' : 'auto'">
            <v-img :width="mobile ? '100%' : 100" src="@/assets/isos/isae3000_t1.png" class="neonBoxRound"/>
        </v-col>
        <v-col :cols="mobile ? '3' : 'auto'">
            <v-img :width="mobile ? '100%' : 100" src="@/assets/isos/iso27001.png" class="neonBoxRound"/>
        </v-col>
        <v-col :cols="mobile ? '3' : 'auto'">
            <v-img :width="mobile ? '100%' : 100" src="@/assets/isos/gdpr.png" class="neonBoxRound"/>
        </v-col>
    </v-row>
</template>
<script>
export default{
    name:"Isos",
    props:{
        alignment:{
            Typeof: String,
            default: null
        },
        mobile:{
            typeof: Boolean,
            default: false
        }
    }
}
</script>
<style>
    .isos-container .v-image__image{
        background-size: contain !important;
        border-radius: 50%;
    }
</style>