import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/validations'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import i18n from './i18n'
import '@/assets/style.css'
import '@/assets/fonts.scss'
import VueCookies from 'vue-cookies'
import FlagIcon from 'vue-flag-icon'
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import { Plugin } from 'vue-responsive-video-background-player'

import axios from "axios";
import VueScrollTo from 'vue-scrollto'




Vue.config.productionTip = false

//mport Mailjet from 'node-mailjet'
//Vue.use(Mailjet)

Vue.use(Plugin);
Vue.use(VuePlyr)
Vue.use(VueScrollTo)


Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueCookies, { expires: '7d'})

Vue.use(FlagIcon)

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
