<template>
    <v-row :class="iconMT ? 'font-weight-light mb-2 mt-0' : 'font-weight-bold mb-4'" style="width:100%;">
        <div style="width: 60px;padding-left: 12px;">
            <v-img width="20px" src="@/assets/icons/CheckSuccess.svg" />
        </div>
        <div :align="labelOnStart ? 'start' : ''" style="width: calc(100% - 60px);" v-if="!iconMT">
            {{label}}
        </div>
        <div :align="labelOnStart ? 'start' : ''" style="width: calc(100% - 60px); font-size: 15px;" v-if="iconMT">
            {{label}}
        </div>
    </v-row>
</template>
<script>
export default{
    name:"IconCheck",
    props:{
        label: {
            typeof: String,
            default: ''
        },
        labelOnStart: {
            typeof: Boolean,
            default: false
        },
        iconMT:{
            typeof: Boolean,
            default: false
        }
    }
}
</script>