<template>
  <div>
    <v-container v-if="isScreen && getCarrossel.length < 8" class="fill-height d-flex min-h-100"
      style="align-items: center; justify-content: flex-end;position: relative; padding-bottom: 400px;">
      <div>{{ getCarrossel.length }}</div>
      <v-row justify="center" style="flex: none;">

        <v-col cols="12" md="12" class="text-center" style="display:flex; align-items: center; width: 100%;">
          <div>
            <div class="text-h5 text-center black--text font-weight-bold mb-10">
              {{ $t('clients_title') }}
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">

              <div v-for="item in getCarrossel" :key="item.id" class="item-client pa-0">
                <div class="card">
                  <div class="card-image-client">
                    <img :src="packImage(item)" alt="" class="mr-4 ml-4" />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="isScreen && getCarrossel.length >= 8" class="fill-height d-flex min-h-100"
      style="align-items: center; justify-content: flex-end;position: relative; padding-bottom: 400px;">
        <div class="text-h5 text-center black--text font-weight-bold mb-10">
          {{ $t('clients_title') }}
        </div>

        <div data-scroll-in-section>
          <div v-for="item in getCarrossel" :key="item.id" class="item-client item-hor-scroll pa-0">
            <div class="card">
              <div class="card-image-client">
                <img :src="packImage(item)" alt="" class="mr-4 ml-4" />
              </div>
            </div>
          </div>

        </div>
      <div class="progress-bar-container">
        <div class="progress-bar-client" ref="progressBar"></div>
      </div>
    </v-container>
    <!-- MOBILE -->
    <v-container v-if="!isScreen" class="fill-height" fluid>
      <div class="clients-card-container">
        <div class="text-h7 text-center black--text font-weight-bold mb-10">
          {{ $t('clients_title') }}
        </div>
        <div class="clients-card-scroll">
          <div v-for="(item, i) in getCarrossel" :key="i" class="client-card">
            <img :src="packImage(item)" class="client-img" />
          </div>
        </div>
      </div>
    </v-container>
    <!-- MOBILE -->
  </div>
</template>
<script>
export default {
  name: "WhatFind",
  data() {
    return {
      scrollingHorizontally: false,
      scrollToFlag: true,
      progressBarWidth: 0,
    }
  },
  mounted() {
    this.$parent.$on('update-progress', this.updateProgressBar);
  },
  methods: {
    packImage(i) {
      return require('@/assets/clients/' + i);
    },
    updateProgressBar(scrolledPercentage) {
      const progressBarElement = this.$refs.progressBar; // Garanta que este ref exista neste componente
      if (progressBarElement) {
        progressBarElement.style.width = `${scrolledPercentage}%`;
      }
    }
  },
  computed: {
    isScreen() {
      return window.innerWidth >= 768
    },
    getCarrossel() {
      let carrossel = this.$t('clients')
      let carrossel2 = this.$t('clients')

      return carrossel.concat(carrossel2);
    }
  },
  beforeDestroy() {
    // É uma boa prática remover o listener quando o componente for destruído
    this.$parent.$off('update-progress', this.updateProgressBar);
  }
}
</script>
<style>
html {
  overflow: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.scroll-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  height: 90vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.item-client {
  flex-shrink: 0;
  padding: 16px;
  margin-right: 20px;
}

.item-client.item-hor-scroll:last-child {
  margin-right: 80px;
}

.item-client:last-child {
  margin-right: 0px;
}

.card {
  display: flex;
  color: white;
  overflow: hidden;
}

.card-image-client {
  border-radius: 20px;
  border: 5px solid black;
}

.card-image-client img {
  display: block;
  width: 160px;
  height: 130px;
  object-fit: contain;
  border-radius: 10px;
}

.horizontalScroll2 .progress-bar-container {
  width: 70%;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  border: 2px solid black;
  position: absolute;
  bottom: 350px;
}

.progress-bar-client {
  height: 100%;
  background-color: #000000;
  width: 0%;
  /* Iniciar sem preenchimento */
  border-radius: 20px;
}

.v-carousel__controls {
  background: transparent !important;
}

.clients-card-container {
  width: calc(100% + 24px);
  margin-left: -12px;
  padding: 0 24px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.clients-card-container::-webkit-scrollbar {
  display: none;
}

.clients-card-scroll {
  display: flex;
  align-items: center;
  width: max-content;
  flex-direction: column;
  height: 280px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -20px;
}

.client-card {
  width: 40vw;
  height: 130px;
  border: 5px solid black;
  border-radius: 20px;
  margin-right: 20px;
  padding: 10px;
}

.client-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>