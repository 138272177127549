<template>
    <v-container fluid class="ma-0 pa-0 min-h-100-lg">
        <!-- DESKTOP -->
        <v-container fluid class="d-none d-lg-block ma-0 pa-0 min-h-100">
            <v-card class="elevation-0 transparent ma-0 pa-0">
                <v-container class="fill-height py-16 d-flex min-h-100" style="align-items: center; justify-content: center; position: relative;">
                    <v-card-text class="pa-0 justify-center">
                        <v-row>
                            <v-col cols="12" md="6">
                                <span class="text-uppercase font-weight-light text-h3 white--text">{{$t('homepage.the')}} <b class="font-weight-medium">{{$t('homepage.channel')}}</b></span><br/>
                                    <span class="text-uppercase font-weight-light text-h3">{{$t('homepage.to_company')}}.</span>
                            </v-col>
                        </v-row>
                        <v-row>                
                            <v-col cols="12" md="6">
                                <span class="text-h6 font-weight-light">{{$t('homepage.ignoto_is')}}.</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="auto">
                                <div style="background-color: white; border-radius: 100px; height: 100%; width: 5px;"></div>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-2 mb-n2 font-weight-bold">            
                                    <p>{{$t('homepage.secure')}}</p>
                                    <p>{{$t('homepage.confidential')}}</p>
                                    <p>{{$t('homepage.intuitive')}}</p>
                                    <p>{{$t('homepage.conformity')}}</p>
                            </v-col>
                        </v-row>
                        <v-row class="mt-12">
                            <v-col cols="12" md="auto" align="start">    
                                <v-btn small @click="$emit('start-free')" rounded light class="font-weight-bold neonText">
                                    {{$t('globals.30_days_free')}}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" align="start">                    
                                <v-btn small @click="$emit('scroll-to', '#contacts')" rounded outlined class="font-weight-bold">
                                    {{$t('globals.team_speak')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <div class="circle-scroll-container">
                            <div class="circle-container">
                                <img src="@/assets/backgrounds/circulo_banner_center.png"/>
                            </div>
                            <div class="scroll-container" ref="scrollContainer">
                                <ul>
                                <li
                                    v-for="(item, index) in computedItems"
                                    :key="index"
                                    :class="{ active: index === activeIndex }"
                                >
                                    {{ item }}
                                </li>
                                </ul>
                            </div>
                        </div>
                    </v-card-text>
                </v-container>
            </v-card> 
        </v-container>        
        <!-- DESKTOP -->
        <!-- MOBILE -->
        <v-container fluid class="d-lg-none ma-0 pa-0 mt-16">
            <v-card class="elevation-0 black ma-0 pa-0">
                <v-card-text>
                    <v-img style="display:block; margin:auto;" src="@/assets/icons/logobranco_comslogan_pequeno_final.png" width="186px"/>
                </v-card-text>
                <v-card-text class="justify-center text-center">
                    <v-row>
                        <v-col cols="12" md="5">
                            <span class="text-uppercase font-weight-light white--text" style="font-size: 28px;line-height: 1.4;"><b class="font-weight-bold">{{$t('homepage.the')}} {{$t('homepage.channel')}}</b></span>
                            <br/>
                            <span class="text-uppercase font-weight-medium white--text" style="font-size: 28px;line-height: 1.4;">{{$t('homepage.to_company')}}</span>
                        </v-col>
                    </v-row>
                    <v-row>                
                        <v-col cols="12" md="5">
                            <span class="text-h7 font-weight-light white--text" style="font-size: 16px;line-height: 1.2;">{{$t('homepage.ignoto_is')}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="auto">
                            <div style="background-color: white; border-radius: 100px; height: 100%; width: 5px;"></div>
                        </v-col>
                        <v-col cols="12" align="center">
                          <div class="scroll-container mb-8" ref="scrollContainer2">
                                <ul>
                                  <li
                                      v-for="(item, index) in computedItems"
                                      :key="index"
                                      :class="{ active: index === activeIndex }"
                                  >
                                      {{ item }}
                                  </li>
                                  </ul>
                              </div>
                        </v-col>
                       
                    </v-row>
                    
                </v-card-text>
            </v-card> 
        </v-container>
        <!-- MOBILE -->
    </v-container>
</template>
<script>
export default {
    name: 'Homepage',
  data() {
    return {
      activeIndex: 1, // Começa com o primeiro item como ativo,
      items: this.$t('homepage.intro_keys'),
      autoScrollTimer: null
    };
  },
  mounted() {
    this.startAutoScroll();
  },
  beforeDestroy() {
    this.stopAutoScroll(); // Parar o timer ao destruir o componente
  },
  computed:{
    computedItems(){
      return this.$t('homepage.intro_keys')
    }
  },
  methods: {
    scrollToActiveItem() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        const newPosition = (this.activeIndex - 1) * 80; // Calcula a nova posição baseada no índice ativo
        container.scrollTo({
          top: newPosition,
          behavior: 'smooth'
        });

        const container2 = this.$refs.scrollContainer2;
        const newPosition2 = (this.activeIndex - 1) * 80; // Calcula a nova posição baseada no índice ativo
        container2.scrollTo({
          top: newPosition2,
          behavior: 'smooth'
        });
      });
    },
    startAutoScroll() {
        
        const container = this.$refs.scrollContainer;
        container.addEventListener("wheel", e => e.preventDefault(), { passive:false })

        const container2 = this.$refs.scrollContainer2;
        container2.addEventListener("wheel", e => e.preventDefault(), { passive:false })

      if (this.autoScrollTimer) {
        clearInterval(this.autoScrollTimer); // Limpar o timer existente para evitar múltiplos intervalos
      }
      this.autoScrollTimer = setInterval(() => {
        if(this.activeIndex == this.items.length-2) this.activeIndex = 1;
        else this.activeIndex = (this.activeIndex + 1) % this.items.length;
        this.scrollToActiveItem();
      }, 2000); // Muda o item ativo a cada 3 segundos
    },
    stopAutoScroll() {
      if (this.autoScrollTimer) {
        clearInterval(this.autoScrollTimer);
      }
    }
  },
}
</script>

<style scoped>
.circle-scroll-container{
    position: absolute;
    top: 0;
    right: 12%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}
.circle-scroll-container .circle-container{
    height: 100%;
    position: absolute;
    border-radius: 30%;
    box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,1);
}
.circle-scroll-container img{
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: -1;
    position: relative;
    display: block;
    border-radius: 30%;
}
.scroll-container {
  overflow-y: auto;
  height: 240px;
  width: 300px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

li {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px #FFFFFFE6;
  opacity: 0.6;
  font-size: 24px;
  text-align: center;
  line-height: 1.2;
}

li.active {
  opacity: 1;
}

@media screen and (max-width: 1263px){
  .scroll-container{
    margin: 0;
  }
}
</style>