<template>
  <component :is="vista_a_abrir" v-if="loaded" @close="$emit('close')"></component>
</template>
<script>
export default{
  name:"TermsConditions",
  data(){
    return{
      loaded: false,
      vista_a_abrir: null
    }
  },
  async mounted(){
    if(this.$i18n.locale == 'pt'){
      const component = await import('@/views/privacy_policy/PrivacyPolicy_pt.vue');
      this.vista_a_abrir = component.default
    }
    if(this.$i18n.locale == 'en'){
      const component = await import('@/views/privacy_policy/PrivacyPolicy_en.vue');
      this.vista_a_abrir = component.default
    }
    if(this.$i18n.locale == 'es'){
      const component = await import('@/views/privacy_policy/PrivacyPolicy_es.vue');
      this.vista_a_abrir = component.default
    }
    if(this.$i18n.locale == 'fr'){
      const component = await import('@/views/privacy_policy/PrivacyPolicy_fr.vue');
      this.vista_a_abrir = component.default
    }
    if(this.$i18n.locale == 'it'){
      const component = await import('@/views/privacy_policy/PrivacyPolicy_it.vue');
      this.vista_a_abrir = component.default
    }

    this.loaded = true
  }
}
</script>