<template>
    <v-container class="fill-height black--text d-flex min-h-100-lg" style="align-items: center; justify-content: center;">
        <!-- DESKTOP -->
        <v-container fluid class="d-none d-lg-block pt-40">
            <v-row justify="center">
                <v-col cols="12" class="text-uppercase text-h4 font-weight-medium text-center">
                    <v-container>
                        <p>{{$t('security.the')}} <b>{{$t('security.security')}}</b> {{$t('security.and_the')}} <b>{{$t('security.privacy')}}</b> {{$t('security.data')}},<br/> {{$t('security.compromisse')}}</p>
                    </v-container>
                </v-col>
                <v-row style="position: relative;">
                    <v-col cols="12" md="6">
                        <v-row >
                            <v-col cols="12" class="text-uppercase font-weight-bold text-h4 mb-8">
                                {{$t('security.why')}}
                            </v-col>
                            <v-col cols="12">
                                <IconCheck :label="$t('security.easy')"/>
                            </v-col>
                            <v-col cols="12">
                                <IconCheck :label="$t('security.personalize')"/>
                            </v-col>
                            <v-col cols="12">
                                <IconCheck :label="$t('security.support')"/>
                            </v-col>
                            <v-col cols="12">
                                <IconCheck :label="$t('security.encript')"/>
                            </v-col>
                            <v-col cols="12">
                                <IconCheck :label="$t('security.certify')"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <div style="position: absolute;width: 5px;height: 100%;left: calc(50% - 2.5px);">
                        <div style="background-color: black; border-radius: 100px; height: 100%; width: 5px;"></div>
                    </div>
                    <v-col cols="12" md="6">
                        <v-row class="pl-6">
                            <v-col cols="12" class="text-uppercase font-weight-bold text-h4">
                                {{$t('security.is_mandatory')}}
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="6" class="font-weight-bold">
                                        <p>{{$t('security.public_entities')}}:</p>
                                        <observer @on-change="habitant_animation" class="test-lazy">
                                            <p class="text-h4 font-weight-bold">+ {{fields.habitants}} {{$t('globals.thousand')}}</p>
                                        </observer>
                                        <p class="text-uppercase text-caption mt-n4">{{$t('globals.population')}}</p>
                                    </v-col>
                                    <v-col cols="12" md="6" class="font-weight-bold">
                                        <p>{{$t('security.private_entities')}}:</p>
                                        <observer @on-change="workers_animation" class="test-lazy">
                                            <p class="text-h4 font-weight-bold">+ {{fields.workers}}</p>
                                        </observer>
                                        <p class="text-uppercase text-caption mt-n4">{{$t('globals.workers')}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-row>
            <div class="mt-16" style="height: 240px;">
                <Isos />
                <v-row class="pa-0">
                    <v-col cols="12" class="text-center font-weight-medium text-h6">
                        {{$t('security.avoid')}}
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn small @click="$emit('start-free')" rounded class="black font-weight-bold">{{$t('globals.try_now')}}</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <!-- DESKTOP -->
        <!-- MOBILE -->
        <v-container class="d-lg-none py-8">
            <v-row justify="center">
                <v-col cols="12">
                    <p class="text-uppercase font-weight-medium text-center" style="font-size: 19px;">{{$t('security.the')}} <b>{{$t('security.security')}}</b> {{$t('security.and_the')}} <b>{{$t('security.privacy')}}</b> {{$t('security.data')}}, {{$t('security.compromisse')}}</p>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col justify="start" cols="12" class="text-uppercase font-weight-bold mb-n2" style="font-size: 13px;">
                            <span>{{$t('security.why')}}</span>
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col cols="12">
                            <IconCheck :labelOnStart="true" :iconMT="true" :label="$t('security.easy')"/>
                            <IconCheck :labelOnStart="true" :iconMT="true" :label="$t('security.personalize')"/>
                            <IconCheck :labelOnStart="true" :iconMT="true" :label="$t('security.support')"/>
                            <IconCheck :labelOnStart="true" :iconMT="true" :label="$t('security.encript')"/>
                            <IconCheck :labelOnStart="true" :iconMT="true" :label="$t('security.certify')"/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="auto">
                    <div style="margin: auto; display: block; background-color: grey; border-radius: 100px; height: 1px; width: 80%;"></div>
                </v-col>
                <v-col cols="12">
                    <v-row class="text-center">
                        <v-col cols="12" class="text-uppercase font-weight-bold" style="font-size: 19px;">
                            {{$t('security.is_mandatory')}}
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" md="6" class="font-weight-bold">
                                    <p class="font-weight-medium">{{$t('security.private_entities')}}:</p>
                                    <observer @on-change="workers_animation" class="test-lazy">
                                        <p class="text-h4 font-weight-bold">+ {{fields.workers}}</p>
                                    </observer>
                                    <p class="text-uppercase text-caption mt-n4 mb-0">{{$t('globals.workers')}}</p>
                                </v-col>
                                <v-col cols="12" md="6" class="font-weight-bold text-center">
                                    <p class="font-weight-medium">{{$t('security.public_entities')}}:</p>
                                    <observer @on-change="habitant_animation" class="test-lazy">
                                        <p class="text-h4 font-weight-bold">+ {{fields.habitants}} {{$t('globals.thousand')}}</p>
                                    </observer>
                                    <p class="text-uppercase text-caption mt-n4 mb-0">{{$t('globals.population')}}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>            
            <v-col cols="12" class="mt-8">
                <v-row class="pa-0">
                    <div class="text-center font-weight-medium mx-auto" style="font-size: 14px; width: 270px;">
                        {{$t('security.avoid')}}
                    </div>
                </v-row>
                <Isos class="mt-6" :mobile="true"/>
            </v-col>
        </v-container>
        <!-- MOBILE -->
        
    </v-container>
</template>
<script>
import IconCheck from '@/components/icons/IconCheck.vue'
import Isos from '@/components/isos/Isos.vue'
import Observer from 'vue-intersection-observer'

export default{
    name:"Security",
    components:{
        IconCheck,
        Isos,
        Observer
    },
    data(){
        return{
            fields: {
                habitants: 0,
                workers: 0
            }
        }
    },
    mounted(){
    },
    methods:{
        habitant_animation(){
            let n = 10
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / 2500, 1);
                this.fields.habitants = Math.floor(progress * (n - 0) + 0);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        },
        workers_animation(){
            let n = 50
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / 2500, 1);
                this.fields.workers = Math.floor(progress * (n - 0) + 0);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }
    }
}
</script>