<template>
    <v-container fluid class="ma-0 pa-0">
        <!-- DESKTOP -->
        <v-container fluid class="d-none d-lg-block pa-0">

            <v-card
                style="display: block; border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;overflow:hidden;max-height: 100vh;"
                class="rounded-xl transparent" width="100%">
                <div class="ma-0 pa-0">
                    <iframe class="bg-video"
                        src="https://player.vimeo.com/video/938586767?autoplay=1&muted=1&loop=1&title=0&sidedock=0&controls=0"
                        frameborder="0" allowfullscreen allow="autoplay; encrypted-media">
                    </iframe>
                </div>
                <div class="video-overlay">
                    <div style="width:100%;">
                        <div class="container">
                            <v-row>
                                <v-col cols="12" class="text-center text-h4 text-uppercase">
                                    {{ $t('contest_process2.process') }}
                                </v-col>
                            </v-row>
                            <v-row class="mt-12 py-6">
                                <v-col cols="12" lg="6" align="end">
                                    <v-btn small class="font-weight-bold neonText" @click="choosed = 0" rounded
                                        :light="choosed == 0" :outlined="choosed == 1" width="250">{{
                                        $t('contest_process2.whistleblower') }}</v-btn>
                                </v-col>
                                <v-col cols="12" lg="6" align="start">
                                    <v-btn small class="font-weight-bold" @click="choosed = 1" rounded
                                        :light="choosed == 1" :outlined="choosed == 0" width="250">{{
                                        $t('contest_process2.manager') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-2" style="width:100%;">
                                <v-col style="background-color: rgba(0,0,0,0.7); border-radius:24px;" cols="12" lg="9" xl="7"
                                    align="center" @mouseenter="disableParentScroll" @mouseleave="enableParentScroll">
                                    <v-virtual-scroll class="scroller" :bench="benched" :items="items" height="410"
                                        item-height="100">
                                        <template v-slot:default="{ item }">
                                            <v-card class="elevation-0 transparent" style="height: 105px; max-height: 105px; display: flex; align-items: center;">
                                                <div style="width: 100%;">
                                                <v-card-title class="text-uppercase font-weight-bold pb-0">
                                                    {{ item.title }}
                                                </v-card-title>
                                                <v-card-text class="text-start">
                                                    <v-row>
                                                        <v-col cols="12" lg="10">
                                                            <span>{{ item.text }}</span>
                                                        </v-col>
                                                        <v-col cols="12" lg="2">
                                                            <span
                                                                :class="choosed == 0 ? 'neonTextPiscaVerde text-caption' : 'neonTextPiscaVermelho text-caption'"
                                                                style="position: relative; float: right !important;">{{
                                        item.tag }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </div>
                                            </v-card>
                                            <v-divider></v-divider>
                                        </template>
                                    </v-virtual-scroll>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-container>
        <!-- DESKTOP -->
        <!-- MOBILE -->
        <v-container fluid class="d-lg-none pa-0">
            <v-card style="display: block; margin: auto;border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;">
                <v-img gradient="rgba(0,0,0,.6), rgba(0,0,0,.6)" src="@/assets/backgrounds/video_processo_denuncia.jpg"
                    style="display: flex; justify-content: center; align-items: center;" class="min-h-100">
                    <v-row>
                        <v-col cols="12" class="text-center text-uppercase mt-12 font-weight-bold"
                            style="font-size: 17px;">
                            {{ $t('contest_process2.process') }}
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="6" lg="6" align="end">
                            <v-btn small class="font-weight-regular neonText" style="font-size: 9px;" @click="choosed = 0" rounded
                                :light="choosed == 0" :outlined="choosed == 1" width="80%">{{
                                        $t('contest_process2.whistleblower') }}</v-btn>
                        </v-col>
                        <v-col cols="6" lg="6" align="start">
                            <v-btn small class="font-weight-regular" style="font-size: 9px;" @click="choosed = 1" rounded
                                :light="choosed == 1" :outlined="choosed == 0" width="80%">{{
                                        $t('contest_process2.manager')
                                    }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-container>
                        <v-row justify="center" class="mt-2">
                            <v-col cols="12" align="center">
                                <v-card v-for="(item, i) in getItemsMobile" :key="i" class="elevation-0 transparent">
                                    <v-card-title class="text-uppercase font-weight-bold py-0 px-3"
                                        style="font-size: 12px; line-height: 1.4;">
                                        {{ item.title }}
                                    </v-card-title>
                                    <v-card-text class="text-start" style="font-size: 13px; line-height: 1.4;">
                                        {{ item.text }}
                                        <v-divider color="white" class="mt-2"></v-divider>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="12" justify="center" class="text-center pt-0 mb-4">
                                <v-btn small @click="moreOrLess" dark class="rounded-xl font-weight-bold" style="background-color: #14141593; border: 2px solid #fff;">{{ maxItems > 3 ? $t('globals.see_less') : $t('globals.see_more')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-img>
            </v-card>
        </v-container>
        <!-- MOBILE -->
    </v-container>
</template>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
    const iframe = document.querySelector('iframe');
    const player = new Vimeo.Player(iframe);

    player.on('play', function() {
        console.log('played the video!');
    });

    player.getVideoTitle().then(function(title) {
        console.log('title:', title);
    });
</script>
<script>

export default{
    name:"ContestProcess",
    data(){
        return{
            benched: 0,
            choosed: 0,
            maxItems: 3,
        }
    },
    computed: {
      items () {
        return this.choosed == 0 ? this.$t('contest_process2.items') : this.$t('contest_process2.items_Gestor')
      },
      getItemsMobile(){
        return  this.choosed == 0 ? this.$t('contest_process2.items').slice(0, this.maxItems) : this.$t('contest_process2.items_Gestor').slice(0, this.maxItems)
        },
      length () {
        return 7000
      },
    },
    methods: {
        disableParentScroll() {
            this.$emit('control-scroll', false);
        },
        enableParentScroll() {
            this.$emit('control-scroll', true);
        },
        moreOrLess(){
            if(this.choosed == 0){
                if(this.maxItems >= this.$t('contest_process2.items').length) this.maxItems = 3
                else this.maxItems = this.$t('contest_process2.items').length;
            }
            else{
                if(this.maxItems >= this.$t('contest_process2.items_Gestor').length) this.maxItems = 3
                else this.maxItems = this.$t('contest_process2.items_Gestor').length;
            }
        }
    }
}
</script>
<style>
.bg-video {
    width: 100%;
    user-select: none;
    pointer-events: none;
    aspect-ratio: 16/9;
    zoom: 1.2;
    transform: scale(1.1);
}

.video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.v-virtual-scroll__container .v-virtual-scroll__item:last-child hr {
    display: none;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.neonTextPiscaVerde {
    animation: flicker-green 1.5s infinite alternate;
    color: #008000;
}

/* Flickering animation */
@keyframes flicker-green {

    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {

        text-shadow:
            0 0 4px #008000,
            0 0 11px #008000,
            0 0 19px #008000,
            0 0 40px #008000,
            0 0 80px #008000,
            0 0 90px #008000,
            0 0 100px #008000,
            0 0 150px #008000;

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}

.neonTextPiscaVermelho {
    animation: flicker-red 1.5s infinite alternate;
    color: #FF0000;
}

/* Flickering animation */
@keyframes flicker-red {

    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {

        text-shadow:
            0 0 4px #FF0000,
            0 0 11px #FF0000,
            0 0 19px #FF0000,
            0 0 40px #FF0000,
            0 0 80px #FF0000,
            0 0 90px #FF0000,
            0 0 100px #FF0000,
            0 0 150px #FF0000;

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}
</style>