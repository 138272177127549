<template>
    <v-dialog v-model="openDialog" fullscreen>
        <v-card class="elevation-0">
            <div class="center-screen">
                <v-container fluid v-if="true">
                    <v-row align="center" justify="center" class="py-16 my-16 ">
                        <v-col cols="12" align="center" class="text-h6">
                            <span>{{$t('thank_you_form.thanks')}}</span>
                        </v-col>
                        <v-col cols="12" align="center" class="text-h4 py-4 my-4">
                            {{$t('thank_you_form.thank_you')}}
                        </v-col>
                        <v-col cols="12" align="center" class="py-4 my-4">
                            <v-btn @click="$router.push('/')" x-large class="black" rounded>
                                {{$t('globals.back')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>

export default{
    name:"ThankYouForm",
    data(){
        return{
            openDialog: true
        }
    },    
    methods:{

    }
}
</script>
<style>
.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
</style>