<template>
    <v-container class="fill-height d-flex" style="align-items: center; justify-content: center;">
    <!-- DESKTOP -->
    <v-container fluid class="d-none d-lg-block py-16" style="padding-bottom: 200px !important;">
        <v-card class="elevation-0" light>
            <v-row align="center">
                <v-col cols="12" class="text-h4 text-center">
                    {{ $t('contacts.contact') }}
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="8" class="text-center">
                    <validation-observer ref="form" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit" class="mb-16 font-weight-light text-caption">
                            <fieldset>
                                <v-row class="mt-4">
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Nome" name="Nome"
                                            rules="required">
                                            <v-text-field v-model="fields.name" dense
                                                :label="$t('contacts.name') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Apelido" name="Apelido"
                                            rules="required">
                                            <v-text-field v-model="fields.last_name" dense
                                                :label="$t('contacts.last_name') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Nome empresa" name="Nome empresa"
                                            rules="required">
                                            <v-text-field v-model="fields.company" dense
                                                :label="$t('contacts.company_name') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Departamento" name="Departamento"
                                            rules="|">
                                            <v-text-field v-model="fields.department" dense
                                                :label="$t('contacts.department')" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Email" name="Email"
                                            rules="required|is_email_valid">
                                            <v-text-field v-model="fields.email" dense
                                                :label="$t('contacts.email') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Telefone" name="Telefone"
                                            rules="|">
                                            <v-text-field v-model="fields.phone" dense :label="$t('contacts.phone')"
                                                clearable :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Mensagem" name="Mensagem"
                                            rules="required|string_size_15">
                                            <v-textarea v-model="fields.description" dense
                                                :label="$t('contacts.message') + ' *'" clearable
                                                :error-messages="errors"></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" class="my-0 py-0">
                                        <v-radio-group v-model="fields.policy">
                                            <v-radio color="black">
                                                <template v-slot:label>
                                                    <div>{{ $t('contacts.privacy_ok') }}</div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="text-center">
                                        <v-btn @click="sendContact" small :disabled="invalid" class="black" rounded
                                            outlined dark>{{ $t('contacts.send_message') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
    <!-- DESKTOP -->
    <!-- MOBILE -->
    <v-container fluid class="d-lg-none mt-12">
        <v-card class="elevation-0" light>
            <v-row align="center">
                <v-col cols="12" class="text-center font-weight-bold text-uppercase pb-0" style="font-size: 20px;">
                    {{ $t('contacts.contact') }}
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="8" class="text-center">
                    <validation-observer ref="form" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit" class="mb-16 font-weight-light text-caption contact-form">
                            <fieldset>
                                <v-row class="mt-4">
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Nome" name="Nome"
                                            rules="required">
                                            <v-text-field v-model="fields.name" dense
                                                :label="$t('contacts.name') + ' *'" clearable
                                                :error-messages="errors" style="font-size: 12px;"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Apelido" name="Apelido"
                                            rules="required">
                                            <v-text-field v-model="fields.last_name" dense
                                                :label="$t('contacts.last_name') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Nome empresa" name="Nome empresa"
                                            rules="required">
                                            <v-text-field v-model="fields.company" dense
                                                :label="$t('contacts.company_name') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Departamento" name="Departamento"
                                            rules="|">
                                            <v-text-field v-model="fields.department" dense
                                                :label="$t('contacts.department')" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Email" name="Email"
                                            rules="required">
                                            <v-text-field v-model="fields.email" dense
                                                :label="$t('contacts.email') + ' *'" clearable
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6" md="6" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Telefone" name="Telefone"
                                            rules="|">
                                            <v-text-field v-model="fields.phone" dense :label="$t('contacts.phone')"
                                                clearable :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12" class="my-0 py-0">
                                        <validation-provider v-slot="{ errors }" vid="Mensagem" name="Mensagem"
                                            rules="required|string_size_15">
                                            <v-textarea v-model="fields.description" dense auto-grow rows="1"
                                                :label="$t('contacts.message') + ' *'" clearable
                                                :error-messages="errors"></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" class="my-0 py-0">
                                        <v-radio-group v-model="fields.policy" class="mt-0">
                                            <v-radio color="black">
                                                <template v-slot:label>
                                                    <div style="font-size: 12px;">{{ $t('contacts.privacy_ok') }}</div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="text-center">
                                        <v-btn @click="sendContact" small :disabled="invalid" class="black" rounded
                                            outlined dark>{{ $t('contacts.send_message') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
    <!-- MOBILE -->
    </v-container>
</template>
<script>
//import Mailjet from 'mailjet-sendemail'

import Mailgun from 'mailgun.js';

export default {
    name: "Contact",
    data() {
        return {
            fields: {
                name: null,
                last_name: null,
                department: null,
                company: null,
                email: null,
                phone: null,
                description: null,
                policy: false
            }
        }
    },
    methods: {
        sendContact() {
            // API KEY: 95ada7f39d8f9d030e46108188736bfe
            // secret: d4e9b0980bc6253ea36361620d0f0b83
            //https://www.npmjs.com/package/node-mailjet
            /*var mailjet = new Mailjet('95ada7f39d8f9d030e46108188736bfe', 'd4e9b0980bc6253ea36361620d0f0b83');
            mailjet.sendContent('email.ignoto.pt', 'erbi.silva@dreamdealtech.pt', 'subject', "text", "content");
            console.log(mailjet)*/

            const mailgun = new Mailgun(FormData);

            const mg = mailgun.client(

                {

                    username: 'api',

                    key: '6f5b57ae0217fc654613cb55c5cc9182-2175ccc2-30e9fa41', //'27d7d132f922d444cef9d1f0c0c38f13-c30053db-9af6b30e', 

                    url: "https://api.eu.mailgun.net"

                }

            );

            let message = "Tem um novo contacto feito da plataforma Ignoto. A mensagem é:<br/><br/>" +
                "Nome: " + this.fields.name + "<br/><br/>" +
                "Apelido: " + this.fields.last_name + "<br/><br/>" +
                "Departamento: " + this.fields.department + "<br/><br/>" +
                "Empresa: " + this.fields.company + "<br/><br/>" +
                "Email: " + this.fields.email + "<br/><br/>" +
                "Contacto: " + this.fields.phone + "<br/><br/>" +
                "Descrição: " + this.fields.description + "<br/><br/>";

            mg.messages.create('email.ignoto.pt', {

                from: "email@ignoto.pt",

                to: ['erbi.silva@dreamdealtech.pt', 'alvaro@dreamdealtech.pt'], //["aldamafonso@hotmail.com"], 

                subject: "Contacto Ignoto",

                text: "Contacto Ignoto",

                html: message

            })

                .then(msg => {
                    console.log(msg)

                    this.$router.push('/' + this.$i18n.locale + '/thankyouform')
                }
                ) // logs response data 

                .catch(err => console.error(err)); // logs any error 
        }
    }
}
</script>
<style>
@media only screen and (max-width: 1263px) {
    .contact-form .v-label{
        font-size: 13px;
    }
}
</style>