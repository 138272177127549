import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout.vue'
import FatherView from '../views/FatherView.vue'
import ThankYouForm from '../views/ThankYouForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => {
      // Detecta o idioma do navegador do usuário
      const userLang = navigator.language || navigator.userLanguage;
      
      // Define o idioma padrão como 'pt' se o idioma não for detectado
      const lang = userLang.startsWith('es') ? 'es' :
                   userLang.startsWith('fr') ? 'fr' :
                   userLang.startsWith('it') ? 'it' :
                   userLang.startsWith('en') ? 'en' :
                   'pt';  // Se não for espanhol, francês ou italiano, redireciona para português

      return `/${lang}`;
    }
  },
  {
    path: '/:lang',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/:lang',
        component: FatherView
      },
      {
        path: '/:lang/thankyouform',
        component: ThankYouForm
      },





      // Politica de Privacidade
      {
        path: '/:lang/politica-de-privacidade',
        component: FatherView
      },
      {
        path: '/:lang/politica-di-privatizzazione',
        component: FatherView
      },
      {
        path: '/:lang/politique-de-confidentialite',
        component: FatherView
      },
      {
        path: '/:lang/politica-de-privacidad',
        component: FatherView
      },
      {
        path: '/:lang/privacy-policy',
        component: FatherView
      },





      // Termos e Condições
      {
        path: '/:lang/termos-condicoes',
        component: FatherView
      },
      {
        path: '/:lang/terminos-condiciones',
        component: FatherView
      },
      {
        path: '/:lang/conditions-generales',
        component: FatherView
      },
      {
        path: '/:lang/termini-condizioni',
        component: FatherView
      },
      {
        path: '/:lang/terms-conditions',
        component: FatherView
      },
      
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
