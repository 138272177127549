<template>
    <v-footer padless light fluid>
        <!-- DESKTOP -->
        <v-container fluid class="d-none d-lg-block ma-0 pa-0">
            <v-row class="ma-0 pa-0 " justify="center">
                <v-col cols="12" md="12" class="black rounded-xl pa-0 rounded-b-0">
                    <v-container>
                        <v-card class="elevation-0 transparent" width="100%" dark>
                            <v-row align="center" justify="center">
                                <v-col class="my-16" cols="12" md="3">
                                    <v-img style="cursor: pointer;" @click="menuClick('home')" class="logo-footer"
                                        src="@/assets/icons/logobranco_comslogan_pequeno_final.png" width="340" />
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-row class="text-start text-caption font-weight-bold">
                                        <v-col cols="12" md="3">
                                            <span style="cursor: pointer;"
                                                @click="menuClick('product')">{{ $t('footer.product') }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            {{ $t('footer.sales') }}
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            {{ $t('footer.support') }}
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            {{ $t('footer.office') }}
                                        </v-col>
                                    </v-row>


                                    <v-row class="text-start mt-n4 text-caption font-weight-bold">
                                        <v-col cols="12" md="3">
                                            <span style="cursor: pointer;"
                                                @click="menuClick('price')">{{ $t('footer.price_plans') }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            {{ $t('footer.email1') }}
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            {{ $t('footer.email2') }}
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            {{ $t('footer.address') }}
                                        </v-col>
                                    </v-row>

                                    <v-row class="text-start mt-n4 text-caption font-weight-bold">
                                        <v-col cols="12" md="3">
                                            <span style="cursor: pointer;"
                                                @click="menuClick('faqs')">{{ $t('globals.frequent_questions') }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            {{ $t('footer.tel') }}: (+351) 220 280 430
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            {{ $t('footer.tel') }}: (+351) 220 280 430
                                        </v-col>
                                        <v-col cols="12" md="3" class="text-caption">
                                            4150-048, Porto
                                        </v-col>
                                    </v-row>


                                </v-col>
                            </v-row>
                        </v-card>
                        <div style="background-color: grey; border-radius: 100px; height: 2px; width: 100%;"></div>
                        <v-row class="grey--text text-caption footer-bottom">
                            <v-col cols="12" md="6">
                                Copyright @2024 Dreamdeal Technologies. {{$t('footer.all_rights')}}.
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row class="grey--text text-caption">
                                    <v-col cols="12" md="4"></v-col>
                                    <v-col cols="12" md="4">
                                        <span style="cursor: pointer;" @click="$emit('privacy')">{{$t('footer.privacy_and_policy')}}</span>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <span style="cursor: pointer;" @click="$emit('terms')">{{$t('footer.terms')}}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <!-- DESKTOP -->
        <!-- MOBILE -->
        <v-row class="d-lg-none ma-0 pa-0 mt-4" justify="center">
            <v-col cols="12" class="black px-6">
                <v-card class="elevation-0 transparent mt-4 mb-4" dark>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="4">
                            <v-img style="display: block; margin: auto;"
                                src="@/assets/icons/logobranco_comslogan_pequeno_final.png" width="200" />
                        </v-col>
                    </v-row>
                    <p class="font-weight-light mt-8"><b>{{ $t('footer.sales') }}: </b>{{ $t('footer.email1') }}<br/><b>{{ $t('footer.support') }}: </b>{{ $t('footer.email2') }}</p>
                    <p class="font-weight-light"><b>{{ $t('footer.office') }}: </b><br/> {{ $t('footer.address') }}, 4150-048, Porto<br/><b>Tel:</b> (+351) 220 280 430</p>
                    <p class="grey--text">-</p>
                    <p class="grey--text">Copyright @2024 Dreamdeal Technologies</p>
                </v-card>
            </v-col>
        </v-row>
        <!-- MOBILE -->
    </v-footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            items: ['pt']
        }
    },
    mounted() {

    },
    methods: {
        menuClick(tag) {
            this.$emit('scroll-to', '#'+tag)
        },
        openIgnoto() {
            window.open('https://backoffice.ignoto.pt', '_blank')
        },
        changeLanguage(l) {
            this.$i18n.locale = l
        }
    },
}
</script>
<style>
.theme--light.v-footer {
    background-color: #fff !important;
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.theme--light.v-footer .black {
    padding-bottom: 80px !important;
}

.theme--light.v-footer .footer-bottom {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.logo-footer .v-image__image {
    background-size: contain;
    background-position: center left !important;
}

@media only screen and (max-width: 1263px) {
    .theme--light.v-footer {
        position: static;
    }
    .theme--light.v-footer .black {
        padding-bottom: 60px !important;
    }
}
</style>