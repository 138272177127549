<template>
    <v-container fluid class="ma-0 pa-0 mt-8-lg">
        <v-card
            style="display: block; border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;"
            class="rounded-xl" width="100%">

            <!-- DESKTOP -->
            <v-img gradient="rgba(0,0,0,.6), rgba(0,0,0,.6)"
                src="@/assets/backgrounds/banner_dados_referentes_denúncias.jpg" class="d-none d-lg-block"
                height="100vh" style="display: flex; justify-content: center; align-items: center;" cover>
            </v-img>
            <v-container fluid style="position: absolute;height: 100%;top: 0;" class="d-none d-lg-flex">
                    <v-row justify="center" align="center">
                        <v-col lg="6">
                            <v-card class="elevation-0 transparent">
                                <v-card-title style="white-space:pre-wrap; word-break:break-word;">
                                    <span class="text-h3">{{ $t('contest_process.data') }}</span>
                                </v-card-title>
                                <v-card-text class="text-body-1 white--text">
                                    {{ $t('contest_process.unique') }}
                                </v-card-text>
                                <v-card-text>
                                    <Isos alignment="start" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            <!-- DESKTOP -->
            <!-- MOBILE -->
            <v-img gradient="rgba(0,0,0,.6), rgba(0,0,0,.6)"
                src="@/assets/backgrounds/banner_dados_referentes_denúncias.jpg" class="d-lg-none min-h-100"
                style="display: flex; justify-content: center; align-items: center;" cover>
                <v-container fluid>
                    <v-card class="elevation-0 transparent text-justify font-weight-bold mt-16">
                        <v-card-title class="font-weight-bold text-uppercase"
                            style="font-size: 17px; line-height: 1.4;">
                            {{ $t('contest_process.data_secure') }}
                        </v-card-title>
                        <v-card-text class="font-weight-light white--text" style="font-size: 16px; line-height: 1.4;">
                            {{ $t('contest_process.portugal') }}
                        </v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col cols="10">
                                    <Isos :mobile="true" alignment="center" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-img>
            <!-- MOBILE -->

        </v-card>
    </v-container>
</template>
<script>
import Isos from '@/components/isos/Isos.vue'

export default {
    name: "ContestProcess",
    components: {
        Isos
    },
    data() {
        return {
            benched: 0
        }
    },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>