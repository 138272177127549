import BaseApi from "./BaseApi";

export default class SubscriptionsTypes extends BaseApi{
    construct() {}

    static async list(params) {
        let url = SubscriptionsTypes.getApiUrl() + "list";

        return SubscriptionsTypes.getAxiosInstance().get(url,{params: params});
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/ignoto-website/subscriptions-types/";
    }
}